import React, { useState, useEffect } from 'react';
import * as styles from './Generator.module.scss';

export default function ThemeGenerator() {
  const [theme, setTheme] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [isCopyButtonDisabled, setIsCopyButtonDisabled] = useState(true);

  useEffect(() => {
    if (theme) {
      getFigmaToken(theme)
        .then(res => {
          const prefix = '--';
          const tokenObject = mappingToken(res, prefix);

          // Remove double quotes, change comma to semicolon, and add space after colon
          const stringJson = JSON.stringify(tokenObject, null, 2).replace(/["]/g, '').replace(/[,]/g, ';');
          setTextareaValue(`:root ${stringJson.replace(/[\[\]]/g, '')}`);

          // Enable copy button
          setIsCopyButtonDisabled(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [theme]);

  const getFigmaToken = async (theme) => {
    const url = `https://gitlab.playcourt.id/api/v4/projects/27138/repository/files/${theme}.json/raw?ref=master`;
    const token = process.env.GATSBY_LGN_GITHUB_TOKEN;

    // Debugging: Log the token
    console.log('Token from env:', token);

    const url_header = { 'PRIVATE-TOKEN' : token };

    try {
      const response = await fetch(url, { method: 'GET', headers: url_header });

      // Debugging: Log the response status
      console.log('Response Status:', response.status);

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching Figma token:', error);
      throw error;
    }
  };

  const kebabCase = (text = '') => {
    return text.replace(/[a-z][A-Z0-9]|[0-9][a-zA-Z]/g, (str) => `${str[0]}-${str[1]}`).toLowerCase();
  };

  const mappingToken = (styleObject, prefix = '') => {
    const newToken = {};

    Object.keys(styleObject).forEach(key => {
      if (typeof styleObject[key] === 'object') {
        const childObject = mappingToken(styleObject[key]);
        Object.keys(childObject).forEach(subKey => {
          newToken[`${prefix}${kebabCase(key)}-${kebabCase(subKey)}`] = `${childObject[subKey]}`;
        });
      } else {
        newToken[`${prefix}${kebabCase(key)}`] = `${styleObject[key]}`;
      }
    });

    return newToken;
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  const handleCopy = (e) => {
    e.preventDefault();
    if (textareaValue === '') return;
    navigator.clipboard.writeText(textareaValue);
    // Change button text
    e.target.textContent = 'Theme now Copied!';
  };

  return (
    <div className={styles.container}>
      <h1>Legion Theme Generator</h1>
      <select name="select-theme" id="select-theme" className={`${styles.fieldBlock} ${styles.selectTheme}`} onChange={handleThemeChange}>
        <option value="">Choose Theme</option>
        <option value="lgn">Legion</option>
        <option value="bbx">BigBox</option>
        <option value="dag">Data Analytics & Governance (DAG)</option>
        <option value="fab">FAB Digital</option>
        <option value="ina">INA Digital</option>
        <option value="nmk">Netmonk</option>
        <option value="pjb">Pijar Belajar</option>
        <option value="pjs">Pijar Sekolah</option>
        <option value="tif">Telkom Infrastruktur Indonesia (TIF)</option>
      </select>
      <label htmlFor="theme-output">Theme Output</label>
      <textarea id="theme-output" className={`${styles.fieldBlock} ${styles.textarea}`} rows="33" value={textareaValue} readOnly></textarea>
      <button className={`${styles.fieldBlock} ${styles.actionCopy}`} onClick={handleCopy} disabled={isCopyButtonDisabled}>Copy Theme</button>
    </div>
  );
};